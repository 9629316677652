import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {getImage, StaticImage} from "gatsby-plugin-image";
import {convertToBgImage} from "gbimage-bridge";
import Header from "../components/header";
import ActionButton from "../components/action-button";
import BackgroundImage from "gatsby-background-image";
import Footer from "../components/footer";

export default function PrivacyPolicyPage() {

    const {blueBg} = useStaticQuery(
        graphql`
        query {
            blueBg: file(relativePath: { eq: "cyan-blue.png" }) {
                childImageSharp {
                    gatsbyImageData(
                      breakpoints: [2500]
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    `
    );

    const bgBlueImage = convertToBgImage(getImage(blueBg));

    return (
        <main className="w-full">
            <BackgroundImage Tag="section" className="relative min-h-screen lg:min-h-3-4-screen w-full" {...bgBlueImage}>
                <div className="absolute w-full">
                    <Header/>
                </div>
                <div className="max-w-7xl mx-auto px-8 pb-8 lg:px-10 lg:pb-10 pt-24 lg:pt-40 min-h-3-4-screen text-white font-poppins">
                    <div className="text-3xl lg:text-5xl">Políticas de Privacidad</div>
                    <div className="mt-10">
                        Datos del responsable del tratamiento:<br/><br/>
                        Identidad: Find IT people - NIF: Y7252574K<br/><br/>
                        Dirección postal: Avenida de Eibar 3, piso 1 puerta A, San fernando de Henares, Madrid<br/><br/>
                        Teléfono: 634449454 - Correo electrónico: info@finditpeople.com<br/><br/>

                        “En Find IT people tratamos la información que nos facilita con el fin de prestarles el servicio solicitado o enviare la información requerida. Los datos proporcionados se conservarán mientras no nos solicite el cese de la actividad. Los datos no se cederán a terceros salvo en los casos en que exista una obligación legal. Usted tiene derecho a obtener información sobre si en Find IT people estamos tratando sus datos personales, por lo que puede ejercer sus derechos de acceso, rectificación, supresión y portabilidad de datos y oposición y limitación a su tratamiento ante Find IT people, Avenida de Eibar 3, piso 1 puerta A, San fernando de Henares, Madrid o en la dirección de correo electrónico gdpr@finditpeople.com, adjuntando copia de su DNI o documento equivalente. Asimismo, y especialmente si considera que no ha obtenido satisfacción plena en el ejercicio de sus derechos, podrá presentar una reclamación ante la autoridad nacional de control dirigiéndose a estos efectos a la Agencia Española de Protección de Datos, C/ Jorge Juan, 6 – 28001 Madrid.
                    </div>
                </div>
            </BackgroundImage>
           
            <Footer/>
        </main>
    );
}